const urlParams = new URLSearchParams(window.location.search);

(async () => {
  if (urlParams.get('dev') !== null) {
    await require('~/lib/gui');
  }
  const Canvas = await require('~/component/Canvas').default;
  window.canvas = new Canvas({
    options: {
      handleReady: () => {
        console.log('READY');
      },
    },
  })


  // setTimeout(() => {
  //   document.querySelector('iframe').style.zIndex = 12
  // }, 5600);
})();


