import * as THREE from 'three';
import IMOG from '~/lib/imog';
import useMouse from '~/lib/imog/use/mouse';
import { map } from '~/lib/math';
import './index.scss';

import Renderer from '~/component/Renderer';
import Caustics from '~/component/Caustics';

export default IMOG.Component('Canvas', {
  options: {
    handleReady: () => { },
    handleItemLoaded: () => { },
  },

  props() {
    return {
      loading: true,
      shouldZoomIn: true,
      // style
      shiftColor1: '#999999',

      speed: 0.08,
      time: Math.random() * 111,

      // //
      // ambientLevels: { x: 0, y: 1, z: 1.3 },
      // highlightsLevels: { x: 0.13, y: 0.9, z: 1 },
      //
      // // post
      // postLevels: { x: 0, y: 0.8, z: 1 },
      // postSaturation: 1.2,

      //
      ambientLevels: { x: 0, y: 1, z: 1.3 },
      highlightsLevels: { x: 0.2, y: 1.05, z: 0.85 },

      // post
      postLevels: { x: 0, y: 0.8, z: 0.65 },
      postSaturation: 2,
      //
      postLevelsW: (props) => ({ ...props.postLevels }),
      postBlur: 40,

      // controls
      zoom: 6,
      mouseIntensity: 0.5,
    };
  },

  setup({ options }) {
    const parent = document.querySelector('.parent')
    setTimeout(() => {
      this.props.shouldZoomIn = true
    }, 1000)

    this.el = document.createElement('div');
    this.el.className = 'canvas-container';
    parent.appendChild(this.el);
    this.renderer = new Renderer({
      options: {
        appendTo: this.el,
      },
      props: {
        bloomActive: false,
        bloomStrength: 0.5,
        bloomThreshold: 0.2,
        bloomRadius: 1,
        zoom: (props) => this.props.zoom,
        mouseIntensity: (props) => this.props.mouseIntensity,
      },
    });


    // this.$renderer.domElement.style.filter = 'blur(5px)';

    this.causticsAmbient = new Caustics({
      options: {
        addTo: this.renderer.worldScene,
      },
      props: {
        tint: (props) => this.props.shiftColor1,
        time: (props) => this.props.time,
        levels: (props) => ({ ...this.props.ambientLevels }),
        z: -5,
      },
    });

    this.causticsAmbient2 = new Caustics({
      options: {
        addTo: this.renderer.worldScene,
      },
      props: {
        tint: (props) => this.props.shiftColor1,
        time: (props) => 2.472 + this.props.time * 0.95,
        levels: (props) => ({ ...this.props.ambientLevels }),
        z: -2.5,
      },
    });

    this.causticsHighlights = new Caustics({
      options: {
        addTo: this.renderer.worldScene,
        useBloom: true,
      },
      props: {
        // tint: (props) => #
        time: (props) => 6.45 - this.props.time * 0.9,
        levels: (props) => ({ ...this.props.highlightsLevels }),
      },
    });

    if (this.$gui) {
      let f = this.$gui.addFolder({
        title: 'Caustics',
        expanded: true,
      });
      f.addInput(this.props, 'shiftColor1');
      f.addInput(this.props, 'ambientLevels');
      f.addInput(this.props, 'highlightsLevels');

      f.addInput(this.props, 'speed');

      f = this.$gui.addFolder({
        title: 'Post',
        expanded: true,
      });

      f.addInput(this.props, 'postLevels');
      f.addInput(this.props, 'postSaturation', { min: 0, max: 2 });

      f = this.$gui.addFolder({
        title: 'Zoom',
        expanded: true,
      });

      f.addInput(this.props, 'zoom', { min: 1, max: 3 });
      f.addInput(this.props, 'mouseIntensity', { min: 0, max: 1 });
    }

    THREE.DefaultLoadingManager.onLoad = () => {
      options.handleReady();
      this.$trigger('mainLoad');
      this.props.loading = false;
    };

    THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      options.handleItemLoaded({ itemsLoaded, itemsTotal });
    };

    THREE.DefaultLoadingManager.onError = function (url) {
      console.log('There was an error loading ' + url);
    };
    THREE.DefaultLoadingManager.itemStart('dummy');
    THREE.DefaultLoadingManager.itemEnd('dummy');
  },

  hooks: {
    'set:postLevelsW'({ x, y, z }) {
      this.renderer.postMaterial.material.uniforms.levels.value = [x, y, z];
      this.renderer.bloomPostMaterial.material.uniforms.levels.value = [
        x,
        y,
        z,
      ];
    },
    'set:postSaturation'(v) {
      this.renderer.postMaterial.material.uniforms.saturation.value = v;
      this.renderer.bloomPostMaterial.material.uniforms.saturation.value = v;
    },
  },

  render() {
    this.props.time += this.props.speed * 0.001;

    if (this.props.shouldZoomIn) {
      if (this.props.zoom <= 45) {
        this.props.zoom = this.props.zoom + 0.01
      }
    }

    if (this.logo) this.logo.render();
    this.renderer.render();
    if (this.flare) this.flare.render();
    if (this.leak && this.leakVisible) this.leak.render();
  },
});
