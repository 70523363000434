import * as THREE from 'three';
import IMOG from '~/lib/imog';
import loadTexture from '~/lib/three/loadTexture';
import useWindowSize from '~/lib/imog/use/windowSize';

export default IMOG.Component('Caustics', {
  options: {
    addTo: null,
    useBloom: false,
  },

  props() {
    return {
      active: true,
      levels: [0, 1, 1],
      time: 0,
      tint: '#fff',
      levels: { x: 0, y: 1, z: 1 },
      z: 0,
    };
  },

  async setup({ options }) {
    this.material = new THREE.ShaderMaterial({
      vertexShader: `
      varying vec2 vUv;

      void main() {
        vec3 pos = position;
        vec4 mvPosition = modelViewMatrix * vec4(pos, 1.0);
        gl_Position = projectionMatrix * mvPosition;
        vUv = uv;
      }`,

      fragmentShader: `
      uniform float time;
      uniform vec3 color1;
      varying vec2 vUv;
      uniform sampler2D tCaustics;
      uniform vec3 levels;

      float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
      vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
      vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}

      float noise(vec3 p){
          vec3 a = floor(p);
          vec3 d = p - a;
          d = d * d * (3.0 - 2.0 * d);

          vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
          vec4 k1 = perm(b.xyxy);
          vec4 k2 = perm(k1.xyxy + b.zzww);

          vec4 c = k2 + a.zzzz;
          vec4 k3 = perm(c);
          vec4 k4 = perm(c + 1.0);

          vec4 o1 = fract(k3 * (1.0 / 41.0));
          vec4 o2 = fract(k4 * (1.0 / 41.0));

          vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
          vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);

          return o4.y * d.y + o4.x * (1.0 - d.y);
      }

      float gammaCorrect(float color, float gamma){
          return pow(color, (1.0/gamma));
      }
      vec3 gammaCorrect(vec3 color, float gamma){
          return pow(color, vec3(1.0/gamma));
      }

      float levelRange(float color, float minInput, float maxInput){
          return min(max(color - (minInput), (0.0)) / ((maxInput) - (minInput)), (1.0));
      }
      vec3 levelRange(vec3 color, float minInput, float maxInput){
          return min(max(color - vec3(minInput), vec3(0.0)) / (vec3(maxInput) - vec3(minInput)), vec3(1.0));
      }
      float finalLevels(float color, float minInput, float gamma, float maxInput){
          return gammaCorrect(levelRange(color, minInput, maxInput), gamma);
      }

      vec3 finalLevels(vec3 color, float minInput, float gamma, float maxInput){
          return gammaCorrect(levelRange(color, minInput, maxInput), gamma);
      }

      float getCaustics(vec2 uv) {
        float v1 = texture2D(tCaustics, uv * 2.8 + vec2(0.0, -time * 2.0)).r * 1.7;
        float v2 = texture2D(tCaustics, uv * 2.3 + vec2(0.0, -time * 1.0)).r * 1.7;
        float v = min(v1, v2);
        float v3 = texture2D(tCaustics, uv * 3.0 + vec2(0.0, time * 2.0)).r * 1.7;
        v = min(v, v3);

        v = finalLevels(v, levels.x, levels.y, levels.z);
        return v;
      }

      void main() {
        vec2 uv = vUv * 1.0;
        vec3 result = vec3(0.0);

        float s = 0.001;
        float r = getCaustics(uv + vec2(+s, +s));
        float g = getCaustics(uv + vec2(+s, -s));
        float b = getCaustics(uv + vec2(-s, -s));
        result = vec3(r, g, b);

        result *= vec3(finalLevels(noise(vec3(vUv * 20.0 + vec2(0.0, -time * 2.0), time * 10.0)), 0.0, 1.0, 0.7));

        result *= color1;

        gl_FragColor = vec4(result, 1.0);

      }
      `,
      uniforms: {
        time: { value: Math.random() * 1000 },
        color1: { value: new THREE.Color() },
        tCaustics: { value: null },
        levels: { value: [0, 1, 1] },
      },
      depthTest: false,
      depthWrite: false,
      blending: THREE.AdditiveBlending,
    });

    const noise = await loadTexture('/caustics2-sl.png');
    noise.wrapS = THREE.RepeatWrapping;
    noise.wrapT = THREE.RepeatWrapping;
    this.material.uniforms.tCaustics.value = noise;

    const g = new THREE.PlaneBufferGeometry(20, 20, 1, 1);
    this.mesh = new THREE.Mesh(g, this.material);
    this.mesh.frustumCulled = false;
    this.mesh.position.z = this.props.z;
    if (options.useBloom) this.mesh.layers.enable(1);
    if (options.addTo) options.addTo.add(this.mesh);
  },

  hooks: {
    'set:tint'(v) {
      this.material.uniforms.color1.value.setStyle(v);
    },
    'set:time'(v) {
      this.material.uniforms.time.value = v;
    },
    'set:levels'({ x, y, z }) {
      this.material.uniforms.levels.value = [x, y, z];
    },
    'set:z'(v) {
      if (this.mesh) this.mesh.position.z = v;
    },
  },
});
