import * as THREE from 'three';
import IMOG from '~/lib/imog';
import { getGPUTier } from 'detect-gpu';
import useWindowSize from '~/lib/imog/use/windowSize';

import OrbitCamera from '~/component/Cameras/OrbitCamera';
import BasicCamera from '~/component/Cameras/BasicCamera';

import PostMaterial from './material';
import BloomPostMaterial from './bloom-material';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from './UnrealBloomPass';

const quadGeometry = new THREE.PlaneBufferGeometry(1, 1);

export default IMOG.Component('Renderer', {
  options: {
    renderDepthBuffer: true,
    appendTo: document.body,
  },

  props() {
    return {
      windowSize: useWindowSize(),
      pr: 1,
      size: (props) => ({
        width: props.windowSize.width,
        height: props.windowSize.height,
        pr: props.pr,
      }),

      cameraType: 'basic',
      bloomActive: false,
      bloomStrength: 0.5,
      bloomThreshold: 0,
      bloomRadius: 0.05,

      zoom: 1,
      mouseIntensity: 1,
    };
  },

  setup({ options }) {
    this.renderer = new THREE.WebGL1Renderer({ antialiased: false });
    this.renderer.domElement.className = 'main-canvas';
    this.renderer.autoClear = false;
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    options.appendTo.appendChild(this.renderer.domElement);
    IMOG.inject('renderer', this.renderer);
    IMOG.inject('rendererProps', this.props);

    // world
    this.worldScene = new THREE.Scene();
    this.orbitCamera = new OrbitCamera();
    this.orbitCamera.init();
    this.worldCamera = this.orbitCamera.camera;
    this.basicCamera = new BasicCamera({
      options: { addTo: this.worldScene },
      props: {
        active: (props) =>
          this.props.cameraType === 'basic' ||
          this.props.cameraType === 'orbit',
        helpers: (props) => this.props.cameraType === 'orbit',
        zoom: (props) => this.props.zoom,
        mouseIntensity: (props) => this.props.mouseIntensity,
      },
    });

    this.worldTarget = new THREE.WebGLRenderTarget(1, 1, {
      format: THREE.RGBFormat,
    });
    this.worldTarget.texture.encoding = THREE.sRGBEncoding;
    this.worldTarget.texture.generateMipmaps = false;
    this.worldTarget.texture.minFilter = THREE.NearestFilter;
    this.worldTarget.texture.magFilter = THREE.NearestFilter;
    this.worldTarget.stencilBuffer = false;
    this.worldTarget.depthBuffer = true;
    this.worldTarget.depthTexture = new THREE.DepthTexture();
    this.worldTarget.depthTexture.format = THREE.DepthFormat;
    this.worldTarget.depthTexture.type = THREE.UnsignedShortType;
    IMOG.inject('worldTarget', this.worldTarget);

    this.vfxTarget = new THREE.WebGLRenderTarget(1, 1, {});
    this.vfxTarget.texture.encoding = THREE.sRGBEncoding;
    this.vfxTarget.texture.generateMipmaps = false;
    this.vfxTarget.texture.minFilter = THREE.NearestFilter;
    this.vfxTarget.texture.magFilter = THREE.NearestFilter;
    this.vfxTarget.stencilBuffer = false;
    this.vfxTargetBloom = new THREE.WebGLRenderTarget(1, 1, {});
    this.vfxTargetBloom.texture.encoding = THREE.sRGBEncoding;
    this.vfxTargetBloom.texture.generateMipmaps = false;
    this.vfxTargetBloom.texture.minFilter = THREE.NearestFilter;
    this.vfxTargetBloom.texture.magFilter = THREE.NearestFilter;
    this.vfxTargetBloom.stencilBuffer = false;

    // screen
    this.bloomPostMaterial = new BloomPostMaterial({
      options: {
        worldTarget: this.worldTarget.texture,
        vfxTarget: this.vfxTarget.texture,
        vfxTargetBloom: this.vfxTargetBloom.texture,
      },
    });
    this.postMaterial = new PostMaterial({
      options: {
        worldTarget: this.worldTarget.texture,
      },
    });
    this.screenScene = new THREE.Scene();
    this.screenQuad = new THREE.Mesh(quadGeometry, this.postMaterial.material);
    this.screenScene.add(this.screenQuad);
    this.screenCamera = new THREE.OrthographicCamera();
    this.screenCamera.position.z = 100;

    // effect composer
    this.renderScene = new RenderPass(this.screenScene, this.screenCamera);

    this.bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      1.5,
      0.4,
      0.85
    );
    this.bloomPass.threshold = this.props.bloomThreshold;
    this.bloomPass.strength = this.props.bloomStrength;
    this.bloomPass.radius = this.props.bloomRadius;

    this.composer = new EffectComposer(this.$renderer);
    // this.composer.addPass(this.renderScene);
    this.composer.addPass(this.bloomPass);
    // this.composer.addPass(this.LinearTosRGBPass);

    if (this.$gui) {
      const fBloom = this.$gui.addFolder({
        title: 'Bloom',
        expanded: true,
      });
      fBloom.addInput(this.props, 'bloomActive');
      fBloom.addInput(this.props, 'bloomStrength');
      fBloom.addInput(this.props, 'bloomThreshold');
      fBloom.addInput(this.props, 'bloomRadius');
    }

    setTimeout(() => {
      // this.props.bloomActive = true;
      if (this.$gui) this.$gui.refresh();
    }, 0);
  },

  hooks: {
    'set:size'({ width, height, pr }) {
      // renderer
      this.renderer.setSize(width, height);
      this.renderer.setPixelRatio(pr);

      // world
      this.worldTarget.setSize(width * pr, height * pr);
      this.worldCamera.aspect = width / height;
      this.worldCamera.updateProjectionMatrix();

      this.vfxTarget.setSize(width * pr, height * pr);
      this.vfxTargetBloom.setSize(width * pr, height * pr);
      this.composer.setSize(width * pr, height * pr);

      //screen
      this.screenCamera.left = (-width * pr) / 2;
      this.screenCamera.right = (width * pr) / 2;
      this.screenCamera.top = (height * pr) / 2;
      this.screenCamera.bottom = (-height * pr) / 2;
      this.screenCamera.updateProjectionMatrix();
      this.screenQuad.scale.set(width * pr, height * pr, 1);
    },

    'set:cameraType'(type) {
      if (type === 'orbit') {
        this.worldCamera = this.orbitCamera.camera;
        this.orbitCamera.controls.reset();
      }
      if (type === 'basic') {
        this.worldCamera = this.basicCamera.camera;
        this.orbitCamera.controls.saveState();
        this.basicCamera.props.wheelActive = true;
      }
    },
    'set:bloomStrength'(v) {
      this.bloomPass.strength = v;
    },
    'set:bloomThreshold'(v) {
      this.bloomPass.threshold = v;
    },
    'set:bloomRadius'(v) {
      this.bloomPass.radius = v;
    },
  },

  methods: {
    render(ms) {
      if (!this.props.bloomActive) {
        this.renderer.setRenderTarget(this.worldTarget);
        this.renderer.clear();
        this.worldCamera.layers.set(0);
        this.renderer.render(this.worldScene, this.worldCamera);
        this.renderer.setRenderTarget(null);
        this.renderer.clear();
        this.screenQuad.material = this.postMaterial.material;
        this.renderer.render(this.screenScene, this.screenCamera);
      } else {
        this.renderer.setRenderTarget(this.worldTarget);
        this.renderer.clear();
        this.worldCamera.layers.set(0);
        this.renderer.render(this.worldScene, this.worldCamera);
        this.renderer.setRenderTarget(this.vfxTarget);
        this.renderer.clear();
        this.worldCamera.layers.set(1);
        this.renderer.render(this.worldScene, this.worldCamera);
        this.bloomPass.render(
          this.$renderer,
          this.vfxTargetBloom,
          this.vfxTarget
        );
        this.renderer.setRenderTarget(null);
        this.renderer.clear();
        this.screenQuad.material = this.bloomPostMaterial.material;
        this.renderer.render(this.screenScene, this.screenCamera);
      }
    },
  },
});
