import * as THREE from 'three';
import IMOG from '~/lib/imog';

export default IMOG.Component('PostMaterial', {
  options: {
    worldTarget: null,
    vfxTarget: null,
    vfxTargetBloom: null,
  },

  setup({ options }) {
    this.material = new THREE.ShaderMaterial({
      vertexShader: /*glsl*/ `
        varying vec2 vUv;

        void main() {
            vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
            gl_Position = projectionMatrix * mvPosition;
            vUv = uv;
        }`,

      fragmentShader: /*glsl*/ `
        varying vec2 vUv;
        uniform sampler2D tWorld;
        uniform float saturation;
        uniform vec3 levels;

        vec3 gammaCorrect(vec3 color, float gamma){
            return pow(color, vec3(1.0/gamma));
        }

        vec3 levelRange(vec3 color, float minInput, float maxInput){
            return min(max(color - vec3(minInput), vec3(0.0)) / (vec3(maxInput) - vec3(minInput)), vec3(1.0));
        }

        vec3 finalLevels(vec3 color, float minInput, float gamma, float maxInput){
            return gammaCorrect(levelRange(color, minInput, maxInput), gamma);
        }

        vec3 czm_saturation(vec3 rgb, float adjustment)
        {
            // Algorithm from Chapter 16 of OpenGL Shading Language
            const vec3 W = vec3(0.2125, 0.7154, 0.0721);
            vec3 intensity = vec3(dot(rgb, W));
            return mix(intensity, rgb, adjustment);
        }

        void main() {
            vec4 worldColor = texture2D(tWorld, vUv);
            gl_FragColor = worldColor;

            gl_FragColor.rgb = finalLevels(gl_FragColor.rgb, levels.x, levels.y, levels.z);
            gl_FragColor.rgb = czm_saturation(gl_FragColor.rgb, saturation);
        }`,
      uniforms: {
        tWorld: { value: options.worldTarget },
        saturation: { value: 1 },
        levels: { value: [0, 1, 1] },
      },
    });
  },
});
